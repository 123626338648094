import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import de la librairie d'animations js Gsap
import gsap from 'gsap'
Vue.use(gsap);

// import du plugin mail (contact)
import{ init } from 'emailjs-com'
init("user_znDWckMS3hPmBtSUjgbMS");

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
