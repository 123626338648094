<template>
  <div class="container">
      <header ref="head" class="head">
          <h1 class="title">Projets réalisés :</h1>
      </header>
      <div class="project-container col-12">
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet personnel : Petits jeux pédagogiques</h2>
                <div class="card-text">
                    <p>Mon fils est très curieux de géographie, j'ai voulu trouver un moyen amusant de lui faire découvrir la France...</p>
                    <p>J'ai mis l'accent sur l'interactivité depuis un ordinateur (les effets de survol ne pouvant pas fonctionner sur un écran tactile)</p>
                    <p>J'ai pu grâce à ce petit projet compléter mes connaissances sur les formats d'images SVG</p>
                    <p>Technologies utilisées : Vue-JS, Javascript, SASS</p>
                </div>
                <router-link to="/FranceGame">
                     <img class="card-img" src="../assets/images/img/jeu-pedagogique.png" alt="apperçu du jeu pédagogique" />
                </router-link>
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet de formation : Reproduire une maquette simple</h2>
                <div class="card-text">
                    <p>Ce projet visant à l'apprentissage du HTML et du CSS, nous demandait de reproduire une maquette de C.V.</p>
                    <p>La forme ainsi que la mise en page nous étaient imposés, </p>
                    <p>cependant, nous étions libres du contenu et des choix de couleurs...</p>
                    <p>Technologies utilisées : HTML CSS W3C</p>
                </div>
                <img class="card-img" src="../assets/images/img/Projet-2.png" alt="apperçu du projet 2" />
                <a href="https://unam38.github.io/Projet-2/" target="_blank"><b-icon-link45deg /> Voir...</a>
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
              <h2 class="card-title">Projet de formation : Reproduction de maquette complexe avec animations</h2>
              <div class="card-text">
                    <p>Dans ce projet, il nous fallait reproduire la maquette, et ajouter des animations CSS...</p>
                    <p>Nous devions également respecter la charte graphique (polices, couleurs, mise en page...)...</p>
                    <p>Initiation au Sass, et à la structure BEM</p>
                    <p>Initiation aux animations CSS</p>
                    <p>Technologies utilisées : HTML CSS SASS W3C</p>
                </div>
                <img class="card-img" src="../assets/images/img/Projet-3.png" alt="apperçu du projet 3">
                <a href="https://unam38.github.io/Projet3/" target="_blank"><b-icon-link45deg /> Voir...</a>
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet de formation : Optimisation de site et SEO</h2>
                <div class="card-text">
                    <p>Dans ce projet, nous devions identifier les disfonctionnements du site...</p>
                    <p>Sensibilisation aux critères d'accessibilité, WCAG, OWASP, et bonnes pratiques</p>
                    <p>Rédiger un ensemble de recommandations d'optimisation technique et SEO, ainsi qu'un plan de tests...</p>
                    <p>Mettre en place le maximum de corrections possibles...</p>
                    <p>Technologies utilisées : HTML, CSS, SASS, Bootstrap, Lighthouse, Google Search Console, GT Metrix</p>
                </div>
                <img class="card-img" src="../assets/images/img/Projet-4.png" alt="apperçu du projet 4">
                <a href="https://unam38.github.io/Projet4/" target="_blank"><b-icon-link45deg /> Voir...</a>
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet de formation : Création d'un site marchand</h2>
                <div class="card-text">
                    <p>Nous avions une API REST fournie, nous permettant de développer le frontend...</p>
                    <p>Développé en javascript vanilla, utilisant bootstrap...</p>
                    <p>Mise en place de REGEX pour la validation du formulaire...</p>
                    <p>J'avais reçu la consigne de n'autoriser qu'une seule quantité par article...</p>
                    <p>Technologies utilisées : HTML, CSS, SASS, Javascript Vanilla, API Rest</p>
                </div>
                <img class="card-img" src="../assets/images/img/Projet-5.png" alt="apperçu du projet 5">
                <a href="https://unam38.github.io/Projet5/frontend/index.html" target="_blank"><b-icon-link45deg /> Voir...</a>
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet de formation : Création d'une API REST</h2>
                <div class="card-text">
                    <p>Dans ce projet, le front nous est fournis, développé avec Angular...</p>
                    <p>Développement de l'API REST - Backend uniquement avec NodeJS, ExpressJS et l' ODM Mongoose pour MongoDB...</p>
                    <p>Développement du serveur, des modèles, routes et controllers...</p>
                    <p>Voir le détail dans mon repo GitHub (lien en bas d'écran)</p>
                    <p>Technologies utilisées : HTML, CSS, SASS, Javascript, API Rest, Node JS, Express JS, bcrypt, Helmet, JsonWebToken, Mongoose, Multer, body-parser, mongoose-unique-validator</p>
                </div>
                <img class="card-img" src="https://picsum.photos/300/200?random" alt="image aléatoire">
          </div>
          <div class="card col-md12 col-lg-4 col-xl-3">
                <h2 class="card-title">Projet final de formation : Création d'un réseau social d'entreprise</h2>
                <div class="card-text">
                    <p>Réalisation de toute l'application Web, Frontend et Backend...</p>
                    <p>Création d'une base de donnée MySql (avec Workbench pour ma part)</p>
                    <p></p>
                    <p>Technologies utilisées :<br/>
                        Frontend : Axios, Bootstrap-Vue, core-js, vue-moment, Vue Js, VueX, Scss<br/>
                        Backend : API Rest, bcrypt, body-parser, dotenv, express, JsonWebToken, MySql, Nodemon, password-validator
                    </p>
                </div>
                <b-icon-tools class="icone" />
          </div>
      </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
    name: "Realisations",
    mounted: function() {
        this.animate()
    },
    methods: {
        async animate() {
            gsap.from(".head", {x: -500, ease: "out", duration: 2})
            gsap.from(".card", 1, {
                scale: 0.1, 
                repeat: 0, 
                ease: "power1.out",
                delay: 1,
                stagger: {
                    amount: 1.5, 
                    grid: "auto",
                    from: "center"
                }
                
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width:768px) {
        margin-top: 60px;
    }
    .head {
    margin: 5px auto;
    background-color: $color3;
    padding: 5%;
    border-radius: 50%;
    @media screen and (min-width:768px) {
        padding: 2%;
    }
    .title {
      font-family: $font3;
      color: $color5;
      font-size: 1.8em;
      text-align: center;
      text-shadow: 2px 2px 2px $color1;
    }
  }
    .project-container {
        width: 100%;
        margin: 0 0 80px 0;
        padding: 0;
        @media screen and (min-width: 768px) {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;
        }
        .card {
            border: 1px solid $color1;
            box-shadow: 2px 2px 2px $color3;
            border-radius: 30px;
            margin: 10px 2% 10px 2%;
            padding: 2%;
            .card-title {
                font-family: $font2;
                font-size: 1.2em;
                text-align: center;
                color: $color1;
            }
            .card-text {
                font-family: $font2;
                font-size: 0.8em;
                color: $color1;
            }
            a {
                display: block;
                position: relative;
                margin: 10px auto;
                cursor: pointer;
                text-decoration: none;
                font-family: $font2;
                background-color: $color2;
                color: $color5;
                font-weight: bold;
                border: 2px solid $color2;
                border-radius: 50%;
                padding: 5%;
                box-shadow: 4px 4px 4px $color3;
                .b-icon {
                    font-size: 1.4em;
                }
            }
            a:hover {
                top: 2px;
                box-shadow: 3px 3px 2px $color3;
            }
            a:active {
                top: 3px;
                box-shadow: 1px 1px 1px $color3;
            }
            .icone {
                display: block;
                position: relative;
                margin: 2px auto;
                color: $color3;
                font-size: 5em;
            }
        }
    }
}
</style>