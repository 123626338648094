<template>
  <div id="about" class="container col-12">
    <header class="about">
      <h1 class="title">Curriculum ...</h1>
    </header>
    <div class="container_sections">
      <h2 class="subtitle">Les Technologies :</h2>
      <p class="text">Dans le cadre de ma reconversion professionnelle, afin de devenir Développeur Web, j'ai pu mener différents projets qui m'ont permis de mettre en pratique les technologies apprises...</p>
      <b-card-group deck class="technologies">
        <div class="row">
          <b-card
            title="HTML5"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-html5"></i></b-card-body>
            <b-card-text class="texte">Dans le respect des normes W3C</b-card-text>
          </b-card>
          <b-card
            title="CSS3"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-css3-alt"></i></b-card-body>
            <b-card-text class="texte">Dans le respect des normes W3C</b-card-text>
          </b-card>
          <b-card
            title="SASS"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-sass"></i></b-card-body>
            <b-card-text class="texte">Avec la méthode BEM</b-card-text>
          </b-card>
          <b-card
            title="Javascript"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-js-square"></i></b-card-body>
          </b-card>
          <b-card
            title="Bootstrap"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-bootstrap"></i></b-card-body>
            <b-card-text class="texte">En mode simple, et en mode Vue JS</b-card-text>
          </b-card>
        </div>
        <div class="row">
          <b-card
            title="VS Code"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><img class=" img" src="../assets/images/logos/logo vsc.png" alt="logo vsc" /></b-card-body>
          </b-card>
          <b-card
            title="GitHub"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-github"></i></b-card-body>
          </b-card>
          <b-card
            title="Node JS"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><i class="icone fab fa-node"></i></b-card-body>
          </b-card>
          <b-card
            title="Express JS"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/express.png" alt="logo express"/></b-card-body>
          </b-card>
          <b-card
            title="MongoDB"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/mongo db.png" alt="logo mongo db"/></b-card-body>
          </b-card>
        </div>
        <div class="row">
          <b-card
            title="Myslq"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/logoMysql.png" alt="logo Mysql"/></b-card-body>
          </b-card>
          <b-card
            title="Vue JS"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/logoVueJS.png" alt="logo Vue JS"/></b-card-body>
          </b-card>
          <b-card
            title="GreenSock"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/greenSock.svg" alt="logo GreenSock"/></b-card-body>
            <b-card-text class="texte">Pour des animations simples et dynamiques</b-card-text>
          </b-card>
          <b-card
            title="Moment JS"
            class="carte col-12 col-md-6 bg-info text-light text-center">
            <b-card-body class="corps"><i class="icone far fa-clock"></i></b-card-body>
          </b-card>
          <b-card
            title="JWT"
            class="carte col-12 col-md-6 bg-secondary text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/JWT.svg" alt="logo JWT"/></b-card-body>
            <b-card-text class="texte">Json Web Token pour sécuriser les échanges</b-card-text>
          </b-card>
        </div>
      </b-card-group>
      <h2 class="subtitle">Ma vie Pro d'avant :</h2>
      <p class="text">Quelques 20 années résumées ici...</p>
      <b-card-group deck class="experiences">
        <b-card class="carte col-12 col-md-8 text-center">
          <b-card-title class="title">Directeur de magasins</b-card-title>
          <b-card-body class="body">
            <b-card-text class="corps">
              <p>Enseignes :</p>
              <ul class="boites">
                <li class="logo l1"><img src="../assets/images/logos/action.png" alt="logo Action"></li>
                <li class="logo l2"><img src="../assets/images/logos/centrakor.png" alt="logo Centrakor"></li>
                <li class="logo l3"><img src="../assets/images/logos/la chopa.png" alt="logo La Chòpa"></li>
                <li class="logo l4"><img src="../assets/images/logos/logo-babou.png" alt="logo Babou"></li>
              </ul>
            </b-card-text>
            <b-card-text class="texte">Direction, gérance de centres de profits pendant près de 13 ans...<br/>
              Management : équipes de 6 à 30 personnes, recrutement, formation, biefings, débriefings, réalisation des plannings...<br/>
              Gestion : analyse des tableaux de bord, définition d'objectifs commerciaux, gestion des flux de marchandises (commandes, réception, inventaires) et monétaires...<br/>
              Commerce : stratégie de communication, relation client, fidélisation...<br/>
            </b-card-text>
          </b-card-body>
        </b-card>
        <b-card class="carte col-12 col-md-8 text-center">
          <b-card-title class="title">Commercial-vendeur</b-card-title>
          <b-card-body class="body">
            <b-card-text class="corps">
              <p>Enseignes :</p>
              <ul class="boites">
                <li class="logo l1"><img src="../assets/images/logos/Logo-Fnac-PNG.png" alt="logo fnac"></li>
                <li class="logo l2"><img src="../assets/images/logos/logo ebi.png" alt="logo EBI"></li>
                <li class="logo l3"><img src="../assets/images/logos/Bower.png" alt="logo Bower"></li>
              </ul>
            </b-card-text>
            <b-card-text class="texte">Attaché commercial, vendeur, chef de rayon pendant près de 8 ans<br/>
              Ebi : commercial Xerox auprès des professionnels en B2B<br/>
              Fnac: Chef de rayon produits nomades, vendeur informatique<br/>
              Bower: Attaché commercial grands comptes
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-card-group>
      <h2 class="subtitle">Ma formation :</h2>
      <p class="text">En bref, mon cursus de formation...</p>
      <b-card-group class="formation">
        <div class="row">
          <b-card
            title="Openclassrooms"
            class="carte col-12 col-md-4 bg-dark text-light text-center">
            <b-card-body class="corps"><img class="img" src="../assets/images/logos/Logo_OpenClassrooms.png" alt="logo Openclassrooms"></b-card-body>
            <b-card-text class="texte">Formation Développeur Web</b-card-text>
          </b-card>
          <b-card
            title="Lycée Gustave FLaubert"
            class="carte col-12 col-md-4 bg-primary text-light text-center">
            <b-card-body class="corps"><img class="img" src="https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/546913_296574667077581_58244299_n.jpg?_nc_cat=102&ccb=2&_nc_sid=d2e176&_nc_ohc=uJOxHrW_sdUAX8cSnAc&_nc_ht=scontent-cdg2-1.xx&oh=79a36365ba439bf69b49fc78e7e25894&oe=602D4BA1" alt="logo Lycée Gustave Flaubert"></b-card-body>
            <b-card-text class="texte">BTS Action Commerciale, Rouen (76)</b-card-text>
          </b-card>
          <b-card
            title="Institution Join Lambert"
            class="carte col-12 col-md-4 bg-dark text-light text-center">
            <b-card-body class="corps"><img class="img" src="https://image-parcours.copainsdavant.com/image/750/1912928303/4213831.jpg" alt="logo Lycée Join Lambert"></b-card-body>
            <b-card-text class="texte">Baccalauréat série B, Rouen (76)</b-card-text>
          </b-card>
        </div>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: "About",
  mounted: function() {
    this.anim()
  },
  methods: {
    async anim() {
      gsap.from("#about", {x:-2000, ease: "out", duration: 1});
    }
  }
  
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  @media screen and (min-width:768px) {
    margin-top: 60px;
  }
  .about {
    .title {
      text-align: center;
      font-family: $font3;
      font-size: 1.5rem;
      color: $color1;
      background-color: $color4;
      border-radius: 50%;
      box-shadow: 6px 6px 6px $color2;
      padding: 2%;
      width: 90%;
      margin: auto;
    }
  }
  .container_sections {
    width: 100%;
    margin: 0;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .subtitle {
      text-align: center;
      font-family: $font3;
      font-size: 1.2em;
      color: $color1;
      margin: 2% auto;
      padding: 2%;
      width: 80%;
      background-color: $color4;
      border-radius: 50%;
      box-shadow: 6px 6px 6px $color3;
    }
    .text {
      text-align: center;
      font-family: $font2;
      font-size: 1em;
      color: $color1;
      padding: 1%;
      margin: 2% auto;
      border: 1px solid $color2;
      border-radius: 20px;
    }
    .technologies {
      display: flex;
      flex-direction: column;
      width: 100%;
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .carte {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 2%;
          width: 80px;
          height: 220px;
          .card-title {
            font-size: 1.2em;
            font-family: $font2;
          }
          .corps {
            display: flex;
            justify-content: center;
            margin: 1%;
            padding: 0;
            .icone {
              font-size: 3em;
              color: $color5;
            }
            .img {
              max-width: 90%;
              min-width: 80%;
              max-height: 75px;
            }
          }
          .texte {
            font-size: 0.8em;
            font-family: $font2;
          }
        }
      }
    }
    .experiences {
      .card-title {
        font-family: $font2;
        color: $color1;
      }
      .carte {
        background-color: $color5;
        .corps {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100px;
          padding: 0;
          margin: 0;
          font-family: $font2;
          color: $color1;
          text-decoration: underline;
          font-weight: bold;
          font-size: 1.2em;
          .boites {
            list-style: none;
            font-family: $font2;
            text-decoration: underline;
            font-weight: bold;
            font-size: 1.2em;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            padding: 0;
            margin: 2%;
            .logo {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              max-width: 20%;
              img {
                width: 100%;
              }
            }
          }
        }
        .texte {
          margin: 2% auto;
          width: 90%;
          font-family: $font2;
          font-size: 1em;
          color: $color1;
        }
      }
    }
    .formation {
      display: flex;
      flex-direction: column;
      width: 100%;
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .carte {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 2%;
          .card-title {
            font-size: 1.2em;
            font-family: $font2;
          }
          .corps {
            display: flex;
            justify-content: center;
            margin: 1%;
            padding: 0;
            .img {
              width: 50%;
              max-height: 75px;
            }
          }
          .texte {
            font-size: 0.8em;
            font-family: $font2;
          }
        }
      }
    }
  }
  
  
}
</style>
