<template>
    <div id="france">
        <FRLearn class="bloc"/>
        <FRGame1 class="bloc"/>
        <FRGame2 class="bloc"/>
    </div>
</template>

<script>
import FRLearn from '@/components/France-learn.vue'
import FRGame1 from '@/components/France-Game1.vue'
import FRGame2 from '@/components/France-game2.vue'

    export default {
        name: "France",
        components: {
            FRLearn,
            FRGame1,
            FRGame2
        }
    }
</script>

<style lang="scss" scoped>
#france {
    background-color: $color30;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 60px 0 0 0;
    .bloc {
        width: 100%;
        margin: 0;
    }
}
</style>