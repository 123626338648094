import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Mentions from '../views/Mentions.vue'
import Realisations from '../views/Realisations.vue'
import Hobbies from '../views/Hobbies.vue'
import FranceGame from '../views/FranceGame.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/Hobbies",
    name: "Hobbies",
    component: Hobbies
  },
  {
    path: "/FranceGame",
    name: "FranceGame",
    component: FranceGame
  },
  {
    path: "/Mentions",
    name: "Mentions",
    component: Mentions
  },
  {
    path: "/Realisations",
    name: "Realisations",
    component: Realisations
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: "/app",
  routes
})

export default router
