import { render, staticRenderFns } from "./Hobbies.vue?vue&type=template&id=681a8506&scoped=true&"
import script from "./Hobbies.vue?vue&type=script&lang=js&"
export * from "./Hobbies.vue?vue&type=script&lang=js&"
import style0 from "./Hobbies.vue?vue&type=style&index=0&id=681a8506&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681a8506",
  null
  
)

export default component.exports