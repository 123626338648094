<template>
  <div class="game-container">
    <header class="head">
      <h1 class="title">A toi de jouer !</h1>
      <h2 class="subtitle">Réponds à ce quiz pour tester tes connaissances !</h2>
    </header>
    <section class="quiz">
      <div v-if="showQuiz" class="questionnaire">
        <h3 class="question">{{ currentQuestion.text }}</h3>
        <ol class="questions-list">
          <li v-for="(response, index) in currentQuestion.responses" :key="index">
            <button @click="answer" class="btn choix">{{ response.text }}</button>
          </li>
        </ol>
      </div>
      <div v-if="showGameOver" class="gameover">
        <h3 class="gameover__title">Fin du Quiz !</h3>
        <p class="gameover__text" v-if="score == 10">Bravo ! tu as tout bon !!</p>
        <img class="gameover__image" v-if="score == 10" src="../assets/medal-gold.svg" alt="médaille d'or">
        <p class="gameover__text" v-if="score >= 7 && score < 10">Bravo ! tu es parmis les meilleurs !</p>
        <img v-if="score >= 7 && score < 10" class="gameover__image" src="../assets/medal-silver.svg" alt="médaille d'argent">
        <p class="gameover__text" v-if="score <7 && score >=5">Pas Mal !! mais tu peux faire mieux !</p>
        <img v-if="score <7 && score >=5" class="gameover__image" src="../assets/medal-bronze.svg" alt="médaille de bronze">
        <p class="gameover__text" style="color:#e87045;" v-if="score < 5">Ce score n'est pas digne de toi ! recommences !</p>
      </div>
      <div class="endGame">
        <h3 class="score">Ton score : {{ score }} / {{ questions.length }}</h3>
        <div class="boutons">
          <div class="valid">
            <h4>Valides tes réponses :</h4>
            <button @click="validScore" class="btn">Valider</button>
          </div>
          <div class="reStart">
            <h4>Améliores ton score :</h4>
            <button class="btn" @click="start">Recommencer</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

  export default {
    name: "FR-Game1",
    data: () => {
      return {
        score: 0,
        questionIndex: 0,
        showQuiz: false,
        showGameOver: true,
        questions: [
          {text: "Quel est le chef-lieu de la région Ile de France ?",
          responses : [
            {text: 'Rouen'},
            {text: 'Paris'},
            {text: 'Bordeaux'},
            {text: 'Lille'}
          ],
          correct: "Paris"},
          {text: "Quel est le chef-lieu de la région Auvergne-Rhône-Alpes ?",
          responses : [
            {text: 'Toulouse'},
            {text: 'Dijon'},
            {text: 'Lyon'},
            {text: 'Bordeaux'}
          ],
          correct: "Lyon"},
          {text: "Quelle région a pour chef-lieu Nantes ?",
          responses : [
            {text: 'Bretagne'},
            {text: 'Corse'},
            {text: 'Grand-est'},
            {text: 'Pays de la Loire'}
          ],
          correct: "Pays de la Loire"},
          {text: "Combien d'habitants vivent en région Auvergne-Rhône-Alpes ?",
          responses : [
            {text: '8092598'},
            {text: '5979778'},
            {text: '3335414'},
            {text: '12213447'}
          ],
          correct: "8092598"},
          {text: "Quelle est la superficie de la région Corse ?",
          responses : [
            {text: '27208'},
            {text: '84036'},
            {text: '12011'},
            {text: '8680'}
          ],
          correct: "8680"},
          {text: "Quelle est la plus grande région de France ?",
          responses : [
            {text: 'Ile de France'},
            {text: 'Hauts de France'},
            {text: 'Nouvelle Aquitaine'},
            {text: 'Auvergne Rhône Alpes'}
          ],
          correct: "Nouvelle Aquitaine"},
          {text: "Quel est le chef-lieu de la région Corse ?",
          responses : [
            {text: 'Lille'},
            {text: 'Ajaccio'},
            {text: 'Rennes'},
            {text: 'Toulouse'}
          ],
          correct: "Ajaccio"},
          {text: "Quelle est la région la plus peuplée ?",
          responses : [
            {text: 'Pays de Loire'},
            {text: "Provence Alpes Côte d'Azur"},
            {text: 'Hauts de France'},
            {text: 'Ile de France'}
          ],
          correct: "Ile de France"},
          {text: "Quelle est la région la moins peuplée ?",
          responses : [
            {text: 'Corse'},
            {text: 'Bourgogne Franche-Comté'},
            {text: 'Ile de France'},
            {text: 'Centre Val de Loire'}
          ],
          correct:"Corse"},
          {text: "Quelle région a pour chef-lieu Rouen ?",
          responses : [
            {text: 'Corse'},
            {text: 'Bourgogne Franche-Comté'},
            {text: 'Normandie'},
            {text: 'Centre Val de Loire'}
          ],
          correct: "Normandie"}
        ],
        currentQuestion: {}
      }
    },
    mounted() {
      this.start()
    },
    methods: {
      // Répondre et tester la réponse
      answer(event) {
        let answer = event.srcElement.firstChild.data
        let correct = this.questions[this.questionIndex].correct
        if (answer != correct) {
          this.questionIndex ++;
          this.currentQuestion = this.questions[this.questionIndex]
        } else {
          this.score++
          this.questionIndex ++;
          this.currentQuestion = this.questions[this.questionIndex]
        }
        if(this.questionIndex === this.questions.length) {
          this.showQuiz = false
          this.showGameOver = true
        }
      },
      start() {
        this.score = 0
        this.questionIndex = 0
        this.currentQuestion = this.questions[this.questionIndex]
        this.showQuiz = true
        this.showGameOver = false
      },
      validScore() {
        let result = JSON.parse(localStorage.getItem("quiz"))
        result = this.score
        localStorage.setItem("quiz", result)
      }
    }
  }
</script>

<style lang="scss" scoped>
.game-container {
  background-color: $color40;
  padding: 0;
  .head {
    background-color: $color40;
    color: $color30;
    font-family: $font1;
    text-align: center;
    font-size: 1.5em;
  }
  .quiz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    border: 1px solid $color30;
    margin: auto;
    border-radius: 60px;
    .questionnaire {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 80%;
      .question {
        border-radius: 20px;
        background-color: $color30;
        font-family: $font1;
        font-size: 1.5em;
        text-align: center;
        color: $color40;
        margin: 1%;
        padding: 0;
        width: 100%;
      }
      .questions-list {
        width: 100%;
        border-radius: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        padding: 1%;
        background-color: $color30;
        li {
          margin: 0;
          padding: 0;
        }
        .choix {
          margin: 5px auto 5px auto;
          background-color: $color40;
          color: $color30;
          font-family: $font1;
          font-size: 1em;
          border-radius: 30px;
          width: 120px;
          height: 80px;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
    .gameover {
      color: $color30;
      font-family: $font1;
      text-align: center;
      padding: 0;
      &__title {
        font-size: 2.5em;
        padding: 0;
        margin: 2px;
      }
      &__text {
        color: $color60;
        font-size: 1.5em;
        padding: 0;
        margin: 2px;
      }
      &__image {
        @include shake;
        max-height: 80px;
        padding: 0;
        margin: 0;
      }
    }
    .endGame {
      border: 1px solid $color30;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      padding: 1%;
      margin: 10px auto 10px auto;
      .score {
        font-family: $font1;
        color: $color30;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $color30;
      }
      .boutons {
        display: inline-flex;
        flex-direction: row;
        margin: auto;
        width: 100%;
        .valid, .reStart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;
          h4 {
            text-align: center;
            font-family: $font1;
            font-size: 1em;
            color: $color50;
          }
          button {
            background-color: $color10;
            color: $color40;
            font-family: $font1;
            font-weight: bold;
            border-radius: 20px;
            padding: 5px;
          }
        }
        
      }
    }
  }
}
</style>