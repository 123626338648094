<template>
  <div class="container">
    <header ref="home" class="home">
      <h2 class="titre"><i class="fusee fas fa-space-shuttle"></i> Développeur Web <div class="titre__dot1">. </div>
        <div class="titre__dot2">. </div>
        <div class="titre__dot3">.</div>
      </h2>
      <h3 class="soustitre">Mon dernier projet de formation est presque achevé,<br/><span class="fort"><i class="fas fa-exclamation-triangle"></i> Avis aux recruteurs !! je suis disponible 😄</span></h3>
    </header>
    <article class="article">
      <section class="services">
        <Meteo />
      </section>
      <section class="actualite">
        <h3 class="actualite__title">Du nouveau par ici !</h3>
        <div class="new">
          <div class="new__item">
            <h4 class="new__item__title">Page Hobbies</h4>
            <router-link to="/Hobbies">
              <b-icon-binoculars class="icone text-light" /><br/>
              <span class="texte text-light">Mes Hobbies</span>
            </router-link>
          </div>
          <div class="new__item">
            <h4 class="new__item__title">Petits jeux educatifs !</h4>
            <router-link to="/FranceGame">
              <b-icon-map class="icone text-light"/><br/>
              <span class="texte text-light">Apprendre en jouant !</span>
              <p class="texte">Préférez une tablette ou un ordi pour profiter de ces petits jeux !<br/> la résolution n'est pas adaptée à un smartphone...</p>
            </router-link>
          </div>
        </div> 
      </section>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import Meteo from "@/components/Meteo.vue"
import { gsap } from 'gsap'

export default {
  name: 'Home',
  components: {
    Meteo
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  mounted: function() {
    this.animation();
    this.onSlideStart();
    this.onSlideEnd();
  },
  methods: {
    async animation() {
     let tl = gsap.timeline({defaults: {duration: 3, ease: "none"}});
     tl.from(".home", {x: -500, ease:"out", duration: 3})
      .from(".fusee", {x:800, y: 800, scale: 4, ease: "bounce", duration: 3})
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 80px;
  @media screen and (max-width: 247px){
    margin-top: 100px;
  }
  @media screen and (min-width:768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: $font2;
    color: $color1;
    border: 1px solid $color1;
    border-radius: 50px;
    background-color: $color2;
    box-shadow: 2px 2px 2px $color3;
    padding: 2%;
    .titre {
      display: flex;
      width: 100%;
      padding: 0;
      justify-content: center;
      align-items: center;
      font-family: $font3;
      font-weight: bold;
      color: $color4;
      font-size: 1em;
      @media screen and (min-width:768px) {
        font-size: 1.5em;
      }
      @keyframes bounce {
        from {transform: translateY(0);}
        to {transform: translateY(-8px);}
      }
      &__dot1 {
          @include dots;
          animation: bounce 300ms ease infinite alternate;
          margin-left: 5px;
      }
      &__dot2 {
          @include dots;
          animation: bounce 300ms ease infinite alternate;
          animation-delay: 50ms;
      }
      &__dot3 {
          @include dots;
          animation: bounce 300ms ease infinite alternate;
          animation-delay: 80ms;
      }
      .fusee {
        color: $color3;
        text-shadow: 4px 4px 6px $color5;
        margin-right: 10px;
        transform: rotate(-45deg);
        font-size: 1.2em;
        @media screen and (min-width:768px) {
        font-size: 1.6em;
        z-index: 100;
      }
      }
    }
    .soustitre {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2%;
      font-size: 1em;
      color: $color4;
      .fort {
        color: yellow;
        font-size: 1.5em;
        text-align: center;
        text-shadow: 4px 4px 6px $color6, 4px 4px 4px $color4;
        .fas {
          text-shadow: 4px 4px 6px $color6, 4px 4px 4px $color4;
          @keyframes warning {
            from {color: yellow;}
            to {color: red;}
          }
          animation: warning 200ms infinite alternate;
        }
      }
    }
  }
  .article {
    @media screen and (min-width:1200px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    .services {
      padding: 0;
      margin-top: 20px;
      border-radius: 40px;
      @media screen and (min-width:1200px) {
        width: 30%;
        margin-right: 20px;
      }
    }
    .actualite {
      border: 1px solid $color90;
      border-radius: 30px;
      background-color: $color40;
      box-shadow: 2px 2px 2px $color90;
      margin-top: 10px;
      margin-bottom: 60px;
      @media screen and (min-width:1200px) {
        margin-top: 20px;
        padding: 5px 5px 5px 5px;
        width: 60%;
      }
      &__title {
        text-align: center;
        font-family: $font1;
        font-size: 1.5em;
        font-weight: bold;
        color: $color80;
      }
      .new {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-start;
        &__item {
          display: flex;
          width: 45%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid $color70;
          border-radius: 30px;
          box-shadow: 2px 2px 2px $color70;
          padding: 1%;
          &__title {
            font-family: $font2;
            font-size: 1.2em;
            color: $color70;
            text-align: center;
            margin: 1px auto;
          }
          a {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            padding: 0;
            margin: 0;
            .icone {
              font-size: 1.5em;
              margin: 0;
              padding: 0;
            }
            .texte {
              font-family: $font2;
              color: $color30;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>