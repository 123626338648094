<template>
    <div class="meteo">
        <vue-weather
            api-key="b28c98438a35677f6e67c14c4dc2cfa3"
            language="fr"
            units="uk"
            text-color="#2c7863"
            bar-color="#0d3430"
        />
    </div>
</template>

<script>
  import VueWeather from "vue-weather-widget";

  export default {
    components: {
      VueWeather,
    },
  };
</script>

<style lang="scss" scoped>
.meteo {
    border: 1px solid $color2;
    box-shadow: 2px 2px 2px $color3;
    width: 100%;
    margin: 0;
}
</style>
