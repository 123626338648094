<template>
    <div class="info">
        <h2>Informations légales</h2>
        <div class="corps">
            <h3><i class="far fa-address-card"></i> Coordonnées Personnelles :</h3>
            <ul class="contact">
                <li>Emmanuel Daubeuf</li>
                <li>39 Avenue Marcellin Berthelot</li>
                <li>38100 Grenoble</li>
                <li>France</li>
            </ul>
            <h3><i class="fas fa-address-card"></i> Coordonnées de l'Hébergeur :</h3>
            <ul class="heberg">
                <li>o2switch</li>
                <li>Siret : 510 909 807 00024</li>
                <li>RCS Clermont Ferrand</li>
                <li>SARL au capital de 100 000€</li>
                <li>SARL o2switch</li>
                <li>222-224 Boulevard Gustave Flaubert</li>
                <li>63000 Clermont-Ferrand</li>
                <li>France</li>
            </ul>
            <h3><i class="fas fa-cookie-bite"></i> Les Cookies :</h3>
                <p>Surtout avec des noisettes et du chocolat...</p>
                <p>Concernant ce site, je n'en utilise aucun...</p>
            <h3><i class="far fa-address-book"></i> Données personnelles liées à l'envoi de message par le formulaire de Contact :</h3>
                <p>Le site ne collecte aucune donnée personnelle exception faite des données transmises par le formulaire de contact.</p>
                <p>Ce formulaire de contact de forme très simple, ne stocke aucune donnée,</p>
                <p>et équivaut a un envoi de mail en direct vers mon compte de messagerie gmail.</p>
                <p>Son utilisation est destinée à éviter de laisser mon adresse email personnelle visible par des logiciels de phishing et me protéger, ainsi que mon répertoire de contacts, de mails malveillants.</p>
                <p>Les donnée reçues (Nom et adresse email) seront (à la demande de l'expéditeur) effacés de mon compte de messagerie.</p>
                <p>Je suis seul destinataire de ce message, et je ne partage aucune donnée.</p>
            <h3><i class="fas fa-map-marker-alt"></i> Données de localisation :</h3>
                <p>Le widget Météo utilisé fait appel à la géolocalisation du navigateur à laquelle le visiteur peut librement s'opposer.</p>
                <p>Aucune donnée de localisation n'est stockée.</p>
            <h3><i class="far fa-trash-alt"></i> Demande d'éffacement de données :</h3>
                <p>Pour me demander d'effacer vos données, deux solutions :</p>
                <p>Soit vous me demandez dès l'envoi de votre message de ne pas les concerver,</p>
                <p>Soit il vous suffit de m'envoyer un nouveau message avec la mention :</p>
                <p><strong>Merci de bien vouloir effacer mes données personnelles</strong></p>
                <p>avec votre adresse email pour que je puisse vous retrouver...</p>
            <h3><i class="fas fa-exclamation-triangle"></i> Attention :</h3>
                <p>L'envoi d'un message ne m'oblige en rien à vous répondre si je juge le message inaproprié, injurieux ou à caractère raciste et/ou homophobe.</p>
                <p>Par contre, de tels messages seront systématiquement signalés aux autorités</p>
            <h3><i class="fas fa-graduation-cap"></i> Précision :</h3>
                <p>Je suis encore débutant dans la réalisation de sites internet, et malgré tout le sérieux apporter à leur rédaction,
                si vous constatiez un manquement quelconque concernant ces mentions légales, merci de me le signaler par le biais du formulaire de contact afin que je puisse corriger au plus tôt.</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 180px auto;
    @media screen and (min-width: 335px) {
        margin-top: 130px;
    }
    h2 {
        margin: 10px auto;
        font-size: 1.8em;
        font-family: $font3;
        letter-spacing: 1px;
        @include text-gradient($color2, $color3);
    }
    .corps {
        display: flex;
        flex-direction: column;
        padding: 10px 2% 12px 2%;
        background-color: $color4;
        font-family: $font2;
        color: $color1;
        text-align: center;
        letter-spacing: 1px;
        width: 80%;
        margin-bottom: 70px;
        i {
            color: $color3;
        }
        .contact, .heberg {
            list-style: none;
        }
    }
}
</style>