<template>
    <div class="container">
        <form class="contact-form" @submit.prevent="sendEmail">
            <div class="input">
                <input
                type="text" 
                v-model="name"
                name="name"
                required="true"
                placeholder="Votre Nom..."
                >
            </div>
            <div class="input">
                <input
                type="email" 
                v-model="email"
                required="true"
                name="email"
                pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}"
                placeholder="Votre adresse Email..."
                >
            </div>
            <div class="input">
                <textarea
                name="message"
                v-model="message"
                cols="30" rows="5"
                placeholder="Votre message...">
                </textarea>
            </div>
            <div class="btn">
              <button type="submit">Envoyer</button>
            </div>
        </form>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
    data() {
        return {
            name: "",
            email:"",
            message:""
        }
    },
    methods: {
        sendEmail() {
            emailjs.send("service_39zw5a5","template_j82i4no",{
            user_name: this.name,
            message: this.message,
            user_email: this.email,
            })
            .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
            alert("Message bien envoyé, merci !");
            })
            .catch(error => {
            console.log('FAILED...', error);
            });
            this.name = "";
            this.email = "";
            this.message= "";
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 90px auto;
    width: 95%;
    @media screen and (min-width: 335px) {
        margin-top: 60px;
    }
    .contact-form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        .input {
            margin: 10px 0 0 0;
            input, textarea {
               width: 100%;
               padding: 20px 5px 20px 5px;
               border: 2px solid $color1;;
               box-shadow: 2px 2px 2px $color3;
               border-radius: 30px;
            }
            input:invalid {
                color: red;
            }
            textarea:invalid {
                color: red;
            }
            ::placeholder {
                font-family: $font2;
                margin: 5px auto;
                color: $color1;
                font-size: 1.2em;
                padding-left: 5%;
            }
        }
        .btn {
            margin: 15px auto;
            button {
                display: block;
                position: relative;
                border: 2px solid $color1;
                box-shadow: 5px 5px 5px $color3;
                border-radius: 30px;
                font-family: $font2;
                color: $color4;
                background-color: $color2;
                @media screen and (min-width: 768px) {
                    font-size: 2em;
                }
            }
            button:hover {
                top: 2px;
                box-shadow: 3px 3px 3px $color3;
                background-color: $color1;
            }
            button:active {
                top: 4px;
                box-shadow: 1px 1px 1px $color3;
            }
        }
    }
}
</style>