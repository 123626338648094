<template>
  <div class="hobbies">
      <header class="header">
          <h1 class="title">Mes hobbies !</h1>
          <h2 class="subtitle">Parcequ'il m'arrive parfois de faire autre chose ...</h2>
      </header>
      <section class="container">
            <div class="container__box">
                <h2 class="title"><i class="icone fas fa-camera-retro"></i> Prendre des photos !</h2>
                <div class="images">
                    <b-carousel
                    id="carousel-fade"
                    v-model="slide"
                    :interval="3500"
                    controls
                    indicators
                    background="rgba(0,0,0,0.5)"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    >
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "640"
                        height= "480"
                        src="../assets/images/photos/Photo1.jpg"
                        alt="Retour du printemps aux 2 Alpes"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "640"
                        height= "480"
                        src="../assets/images/photos/Photo2.jpg"
                        alt="Retour du printemps aux 2 Alpes"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo3.jpg"
                        alt="Torrent de montagne aux pieds des 2 Alpes"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo4.jpg"
                        alt="Pont en Royan"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo5.jpg"
                        alt="Paysage de Montagne près de Grenoble"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo6.jpg"
                        alt="Alpages preès de Grenoble"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo7.jpg"
                        alt="Paysage de Montagne près de Grenoble"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo8.jpg"
                        alt="Lac de Freydières"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo9.jpg"
                        alt="Allevard"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo10.jpg"
                        alt="Grenoble vue de haut"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo11.jpg"
                        alt="La neige en Montagne, Prémol"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo12.jpg"
                        alt="La nature sous la neige"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo13.jpg"
                        alt="La nature sous la neige"/>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template v-slot:img>
                        <img
                        class="d-block img-fluid w-100"
                        width= "480"
                        height= "640"
                        src="../assets/images/photos/Photo14.jpg"
                        alt="La nature sous la neige"/>
                        </template>
                    </b-carousel-slide>
                    </b-carousel>

                    <p class="mt-4">
                    Photo : {{ slide }}
                    </p> 
                </div>
            </div>
            <div class="container__box">
                <h2 class="title"><i class=" icone fas fa-walking"></i> La Randonnée !</h2>
                <p class="text">La région de Grenoble s'y prête un peu et puis ça fait du bien de décrocher de l'écran de temps en temps</p>
            </div>
            <div class="container__box">
                <h2 class="title"><i class="icone fas fa-biking"></i> Le vélo !</h2>
                <p class="text">Le plus souvent en famille, sur les berges de l'Isère ou du Drac...</p>
            </div>
            <div class="container__box">
                <h2 class="title"><i class="icone fas fa-tools"></i> Le bricolage !</h2>
                <p class="text">Construire des trucs, assembler des machins, réparer des bidulles, ça fait travailler les mains et ça vide la tête...</p>
            </div>
            <div class="container__box">
                <h2 class="title"><i class="icone fas fa-blender"></i> La cuisine !</h2>
                <p class="text">Trouver une bonne recette, trouver les bons ingrédients, cuisiner, et déguster en famille...</p>
            </div>
            <div class="container__box">
                <h2 class="title"><i class="icone fas fa-plane"></i> Les voyages !</h2>
                <p class="text">Découvrir de nouveaux paysages, de nouvelles cultures...</p>
            </div>
      </section>
  </div>
</template>

<script>
export default {
    data() {
        return {
        slide: 0,
        sliding: null
        }
    },
    mounted: function() {
        this.onSlideStart();
        this.onSlideEnd();
    },
    methods: {
        onSlideStart() {
        this.sliding = true;
        },
        onSlideEnd() {
        this.sliding = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.hobbies {
    margin: 60px auto 60px auto;
    .header {
        border: 2px solid $color1;
        border-radius: 30px;
        background-color: $color50;
        .title {
            text-align: center;
            font-family: $font1;
            font-size: 2em;
        }
        .subtitle {
            text-align: center;
            font-family: $font1;
            font-size: 1.5em;
        }
    }
    .container {
        width: 80%;
        margin-top: 10px;
        &__box {
            .title {
                width: 100%;
                font-family: $font1;
                font-size: 1.5em;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(to bottom, $color30, $color50);
                border-radius: 30px 30px 0 0;
                .icone {
                    font-size: 1.5em;
                    margin-right: 20px;
                }
            }
            .text {
                font-family: $font1;
                font-size: 1.2em;
                text-align: center;
                background: linear-gradient(to bottom, $color20, $color10);
                border-radius: 0 0 30px 30px;
            }
            .images {
                width: 100%;
                background-color: $color30;
            }
        }
    }
}
</style>